import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"
import { MarkdownViewer } from "../components/Markdown"
import { MapCTA } from "../components/CTA"

const SingleVoice = ({ data, pageContext, location }) => {
  const post = data.allVoicesJson.nodes[0]
  const { content, mapCta } = post
  const voices = data.voices.nodes

  return (
    <Layout className="single-voice" navSpacerLarge>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        schemaPage={post.schemaPage ? post.schemaPage : null}
      />

      <Section>
        <Container>
          <div className="single-voice__container">
            <MarkdownViewer markdown={content} />
            <div className="single-voice__vline"></div>
            <div className="single-voice__read-more">
              <h3>Read More</h3>
              <div className="single-voice__blogs">
                {voices.map((item, i) => (
                  <div key={i} className="voices__card minimal">
                    <p className="bold">{item.query.name}</p>
                    <p className="mb-1">{item.query.blurb}</p>
                    <Link
                      className="voices__card-btn"
                      to={`/${item.title}`}
                      title={`Read more about ${item.query.name}`}
                    >
                      Read More
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <MapCTA image={mapCta.image} text={mapCta.text} button={mapCta.button} />
    </Layout>
  )
}

export default SingleVoice

export const singleVoiceQuery = graphql`
  query ($title: String!) {
    allVoicesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        content
        mapCta {
          image
          text
          button {
            buttonText
            appearance
            destination
            href
          }
        }
      }
    }
    voices: allVoicesJson(
      limit: 3
      filter: { title: { ne: $title } }
      sort: { order: ASC, fields: createdAt }
    ) {
      nodes {
        query {
          name
          blurb
        }
        title
      }
    }
  }
`
